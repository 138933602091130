import React from 'react';

import { LinkWrapper } from '@components';
import { Box, Button } from '@core';

const CTAButton = ({ button: { title, link, buttonProps }, backgroundColor, padding }) => (
  <Box
    justifyContent="center"
    display="flex"
    backgroundColor={backgroundColor}
    width="100%"
    {...padding}
  >
    <LinkWrapper link={link} withParams>
      <Button type="button" {...buttonProps}>
        {title}
      </Button>
    </LinkWrapper>
  </Box>
);

export default CTAButton;
